import React        from 'react';
import Flux         from '../flux/Flux';
import Translations from './Translations';
import ReloadHelper from './ReloadHelper';

const TranslationHelper = {
	getTextWithActorLinks(text, linkClass, linkCallback, baseLink) {
		const callbackFn = (match, actorName) => {
			let url = baseLink.replace(':actorName', actorName.toLowerCase());

			return '[url=' + url + ']' + actorName + '[/url]';
		};

		text = text.replace(/actorName:([a-zA-Z0-9-]+)/g, callbackFn);

		return TranslationHelper.parseDescriptionText(text, linkClass, linkCallback);
	},

	getTextWithPreviewName(textKey) {
		const isLoggedIn       = Flux.Guest.isLoggedIn();
		const isVipLivePreview = isLoggedIn && Flux.Guest.isVip();

		const previewName = Translations.get((isVipLivePreview ? 'LivePreviewVipButton' : 'LivePreviewButton'));
		return Translations.get(textKey).replace(':previewName', previewName);
	},

	getTranslationFromSearchCondition: function(searchCondition, translateOther = false) {
		let translation = '';

		switch (searchCondition) {
			case 'actorFigure':
				translation = Translations.get('ProSearchLblFigure');
				break;
			case 'actorCupSize':
				translation = Translations.get('ProSearchLblCupSize');
				break;
			case 'actorHairColor':
				translation = Translations.get('ProSearchLblHairColor');
				break;
			case 'actorAge':
				translation = Translations.get('ProSearchLblAge');
				break;
			case 'actorFetish':
				translation = Translations.get('ProSearchLblFetish');
				break;
			case 'actorPubicHair':
				translation = Translations.get('ProSearchLblPubicHair');
				break;
			case 'actorSexualOrientation':
				translation = Translations.get('ProSearchLblSexualOrientation');
				break;
			case 'actorLanguage':
				translation = Translations.get('ProSearchLblLanguages');
				break;
			case 'actorOther':
				translation = translateOther ? Translations.get('ProSearchLblMisc') : '';
				break;
			default:
		}

		return translation;
	},

	parseDescriptionText: function(text, linkClass, linkCallback) {
		const textArray = [];

		if (text.length > 0) {
			let startPos = 0;
			let endPos   = 0;

			while (startPos >= 0 && endPos >= 0) {
				startPos = text.indexOf('[url=', endPos);

				if (startPos >= 0) {
					textArray.push(text.substring(endPos, startPos));
					endPos = text.indexOf('[/url]', startPos);

					// add length of closing tag
					if (endPos > 0) {
						endPos += 6;

						const link    = text.substring(startPos, endPos);
						const matches = link.match(/\[url=(.*)](.*)\[\/url]/i);
						if (matches.length === 3) {
							const linkLink = matches[1].replace(/"'/gi, '');
							const linkText = matches[2];
							const onClick  = (e) => {
								if (linkCallback) {
									linkCallback(e, linkLink);
								} else {
									ReloadHelper.reload(linkLink, e);
								}
							};
							textArray.push(<a href={linkLink} className={linkClass} onClick={onClick}>{linkText}</a>);
						}
					}
				} else if (endPos > 0) {
					textArray.push(text.substring(endPos));
				}
			}
		}

		return textArray.length > 0 ? textArray : text;
	}
};

export default TranslationHelper;


function getCommonTranslation(key, ...args) {
	return Translations.get(`common.${key}`, ...args);
}

function getMessengerTranslation(key, ...args) {
	return Translations.get(`messenger.${key}`, ...args);
}

function getSeoHeadlineTranslation(key, ...args) {
	return Translations.get(`seo_headlines.${key}`, ...args);
}

function getDailyBonusTranslation(key, ...args) {
	return Translations.get(`daily-bonus.${key}`, ...args);
}

export {
	getCommonTranslation,
	getMessengerTranslation,
	getSeoHeadlineTranslation,
	getDailyBonusTranslation
};